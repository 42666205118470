<template>
  <div id="footer">
    <b-card
      no-body
      class="bg-transparent"
    >
      <div class="p-1 d-flex justify-content-between align-items-center">
        <div :class="`d-flex flex-wrap text-danger ${['xs'].includes(appBreakPoint) ? '' : 'text-title'}`">
          <span class="mr-1 text-nowrap">Tổng tiền:</span>
          <div class="fw-700">
            {{ formatCurrency(totalPrice) }}
          </div>
        </div>

        <div class="d-flex flex-nowrap">
          <b-button
            variant="outline-secondary"
            class="py-1 px-1 px-md-2 mr-1 mr-md-2"
            pill
            @click="backHandle"
          >
            <span class="text-nowrap">
              {{ $t('reservation.back') }}
            </span>
          </b-button>
          <b-button
            variant="gradient border-0 py-1 px-1 px-md-2"
            pill
            :disabled="tabIndexNotSelect === tabIndex"
            @click="handleShowModalConfirm"
          >
            <span class="text-nowrap">
              {{ $t('reservation.confirm') }}
            </span>
          </b-button>
        </div>
      </div>
    </b-card>

    <!-- SECTION modal-modify-add-flight-price -->
    <b-modal
      v-if="!isEmpty(selectedTripToAdd)"
      id="modal-modify-add-flight-price"
      :title="$t('reservation.historyActions.MODIFY_ADD_FLIGHT')"
      title-class="text-heading-3 text-airline font-weight-bolder"
      centered
      lazy
      :no-close-on-backdrop="true"
      size="lg"
      @show="showModalConfirmAddFlight"
      @hide="hideModalConfirmAddFlight"
    >
      <!-- @hide="handleHideModal" -->
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class=" mt-lg-25"
          :disabled="!isConfirm || (['VJ'].includes(bookingData.source) && !priceAdd)"
          :variant="isPayNow ? 'success' : 'warning'"
          pill
          @click="handleSubmitAddFlight"
        >
          <!-- {{ $t('reservation.historyActions.MODIFY_ADD_FLIGHT') }} -->
          <span class="align-middle">
            {{ isPayNow ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU' }}
          </span>
        </b-button>
      </template>

      <!-- ANCHOR: Hành trình mới -->
      <div class="text-heading-4 text-success mt-1 mb-50 mr-50">
        {{ $t('reservation.newFlight') }}:
      </div>

      <b-card
        v-for="(itinerary, index) in selectedTripToAdd"
        :key="index"
        class="mb-sm-2 shadow-lg border-success mx-50 mx-md-2"
        body-class="px-50 px-1 py-1"
      >
        <div
          v-for="(segment, indexSegment) in itinerary.segments"
          :key="indexSegment"
        >
          <div
            class="d-flex flex-wrap fw-700 align-items-center"
            :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
          >
            <span class="mr-50">{{ indexSegment + 1 }}</span>
            <span class="mr-50">{{ resolveAirlineFlightNumber(segment.airline, segment.flightNumber) }}</span>
            <span class="mr-50">{{ itinerary.fareOptions.bookingClass }}</span>
            <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth }}</span>
            <span class="mr-50">{{ segment.departure.IATACode }}{{ segment.arrival.IATACode }}</span>
            <span class="mr-50">{{ segment.status }}</span>
            <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin }}</span>
            <span>{{ convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin }}</span>
          </div>
        </div>
      </b-card>

      <div
        class="mt-1 d-flex-between"
        :class="isMobileView ? '' : 'mx-2'"
      >
        <span
          class="text-black font-weight-bolder mr-25 mr-md-2"
          :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
        >
          Tổng tiền hành trình đã chọn:
        </span>
        <div>
          <span
            class="fw-700"
            :class="isMobileView ? 'font-small-4 text-nowrap' : 'font-medium-4'"
          >
            {{ formatCurrency(totalPrice) }}
          </span>
          <span>(VND)</span>
        </div>
      </div>

      <!-- ANCHOR tính giá cho VJ -->
      <div
        v-if="!isEmpty(selectedTripToAdd) && ['VJ','VZ'].includes(selectedTripToAdd[0].airline)"
        class="mx-md-2"
      >
        <div
          v-if="priceAdd"
          class="text-body font-weight-bold "
          :class="isMobileView ? 'font-small-3' :'font-medium-2'"
        >
          <div
            v-if="totalRefundAmountVJ > 0"
            class="d-flex-between"
          >
            <span
              class="text-black font-weight-bolder mr-25 mr-md-2"
              :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
            >
              Tiền bảo lưu định danh:
            </span>
            <div>
              <span
                class="text-nowrap font-weight-bolder"
                :class="isMobileView ? '' :'font-medium-4'"
              >
                - {{ formatCurrency(totalRefundAmountVJ) }}
              </span>
              <span>(VND)</span>
            </div>
          </div>

          <div class="d-flex-between mt-2">
            <span
              class="text-black font-weight-bolder mr-25 mr-md-2"
              :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
            >
              Tổng tiền phải thanh toán:
            </span>
            <span
              class="text-warning text-nowrap font-weight-bolder"
              :class="isMobileView ? '' :'font-medium-4 mr-1'"
            >
              {{ formatCurrency(priceAdd.total ) }} ({{ priceAdd.currency }})
            </span>
          </div>
        </div>

        <div
          v-else-if="errorAdd"
          class="mt-75"
        >
          <div class="text-danger text-heading-4">
            Lỗi tính giá thêm hành trình, quý đại lý vui lòng thử lại thao tác hoặc liên hệ với booker để xử lý!
          </div>
          <div class="text-danger font-small-4">
            Mã lỗi: {{ errorAdd }}
          </div>
        </div>

        <div
          v-else-if="priceAdd === false"
          class="mt-1 text-info text-center"
        >
          {{ $t('reservation.calcPriceForChangeFlight') }}...
          <p class="text-center">
            <b-spinner
              variant="info"
              small
            />
          </p>
        </div>
      </div>

      <CardAncillaryPayOptions
        v-if="bookingData && ['VJ'].includes(bookingData.source)"
        :reservations-data="bookingData"
        :is-pay-now.sync="isPayNow"
        :has-paid-trip="hasPaidTrip"
        class="mt-2"
      />

      <IAmConfirm :is-confirm.sync="isConfirm" />
    </b-modal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, BSpinner,
} from 'bootstrap-vue'
import {
  toRefs, watch, ref, computed,
} from '@vue/composition-api'
import {
  compact, groupBy, capitalize, isEmpty, cloneDeep,
} from 'lodash'
import Vue from 'vue'

import store from '@/store'
import { getUserData } from '@/api/auth/utils'
import router from '@/router'
import { resolveTitle } from '@/constants/selectOptions'

import {
  formatCurrency,
  convertISODateTime,
  resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useReservationDetailBookingHandle from '@reservation//reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'
import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BSpinner,
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
    CardAncillaryPayOptions: () => import('@reservation/reservation-modify/components/detail/components/CardAncillaryPayOptions.vue'),

  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { toastSuccess, toastError } = useToast()

    const {
      tabIndex,
      tempSelectedTrips,
      selectedFlightIndex,
      getSearchFlightArray,
    } = useReservationDetailBookingHandle()
    const {
      addFlights,
      addFlightsPrice,
      delay,
    } = useReservationHandle()

    const { bookingData, selectedTrips } = toRefs(props)

    const totalPrice = ref(0)
    const isConfirm = ref(false)
    const selectedTripToAdd = ref([])
    const payloadAddFlight = ref(null)

    const isPayNow = ref(false)
    const hasPaidTrip = ref(false)

    const totalRefundAmountVJ = computed(() => props?.bookingData?.refundAmountIdentification)
    const tabIndexNotSelect = computed(() => selectedTrips.value.findIndex(item => item === null))

    // ====== totalPrice ================
    watch(selectedTrips, () => {
      const tripPrice = selectedTrips.value.reduce((acc, current) => {
        const cur = current ? current.fareOptions.afterMultiple.total : 0
        return acc + cur
      }, 0)
      totalPrice.value = tripPrice
    })

    // === getFareBreakdowns, getItineraries, getPassengerTitle ========
    function getFareBreakdowns(trip, bookingData) {
      const result = []
      const passengerByPaxType = groupBy(bookingData.paxLists, 'paxType')
      for (const paxType in passengerByPaxType) {
        if (Object.prototype.hasOwnProperty.call(passengerByPaxType, paxType)) {
          result.push({
            paxType,
            netFare: trip.airline === 'VJ' ? trip.fareOptions[`fare${capitalize(paxType)}`] : 0,
            discountAmount: trip.airline === 'VJ' ? trip.fareOptions[`discount${capitalize(paxType)}`] : 0,
            tax: trip.airline === 'VJ' ? trip.fareOptions[`tax${capitalize(paxType)}`] : 0,
            total: trip.airline === 'VJ' ? trip.fareOptions[`total${capitalize(paxType)}`] : 0,
            fareValue: trip.airline === 'VJ' ? trip.fareOptions.fareValue : '',
          })
        }
      }
      return result
    }

    const getItineraries = trips => trips.map(({
      fareOptions, clientId, source, segments,
    }) => {
      const fareBreakdowns = getFareBreakdowns(trips[0], props.bookingData)
      const segmentsData = segments.map(({
        leg, airline, departure, arrival, departure: { at: depAt, timezone: depTz }, arrival: { at: arrAt, timezone: arrTz }, flightNumber, marriageGrp, segmentId,
      }) => {
        const {
          fareType, fareBasisCode, bookingClass, groupClass,
        } = fareOptions
        const legIndex = leg - 1
        return {
          leg,
          segmentId: segmentId || 'string',
          airline,
          departure: departure.IATACode,
          arrival: arrival.IATACode,
          departureTime: convertISODateTime(depAt, depTz).ISOdatetime,
          arrivalTime: convertISODateTime(arrAt, arrTz).ISOdatetime,
          flightNumber: flightNumber.toString(),
          fareType: fareType.split(' | ')[legIndex] || fareType,
          fareBasisCode: fareBasisCode.split(' | ')[legIndex] || fareBasisCode,
          bookingClass: bookingClass.split(' | ')[legIndex] || bookingClass,
          groupClass,
          marriageGrp,
          segmentValue: 'string',
        }
      })
      return {
        source,
        clientId,
        fareBreakdowns,
        segments: segmentsData,
      }
    })

    function getPassengerTitle(passenger) {
      if (passenger.title) {
        return resolveTitle(passenger.title) || ''
      }
      const lastOfFirstName = passenger.firstName.split(' ').splice(-1)[0]
      if (resolveTitle(lastOfFirstName)) {
        return resolveTitle(lastOfFirstName)
      }
      return ''
    }

    function handleSubmitAddFlight() {
      const payload = cloneDeep(payloadAddFlight.value)

      if (['VJ'].includes(bookingData.value.source)) {
        payload.isPaymentImmediate = isPayNow.value
      }

      store.dispatch('app/setLoading', true)

      addFlights(payload)
        .then(() => {
          router.go(-1)
          toastSuccess({ title: 'Thành công', content: 'Thêm hành trình thành công!' })
        })
        .catch(error => {
          const errorMessageList = computed(() => store.getters['globalConfig/getErrorMessage'])
          console.error({ error })
          toastError({ title: 'messagesList.error', content: 'Có lỗi xảy ra khi thực hiện thêm hành trình!' })

          if (typeof error?.response?.data === 'object' && error?.response?.data?.message) {
            const textShow = errorMessageList.value.find(i => i.key === error.response.data.message) || errorMessageList.value.find(err => JSON.stringify(error.response.data.message).includes(err.key))
            Vue.swal({
              title: 'Có lỗi xảy ra!',
              text: textShow ? textShow.value : `Vui lòng kiểm tra mã lỗi: ${typeof error.response.data.message === 'object' ? JSON.stringify(error.response.data.message) : error.response.data.message}`,
              icon: 'error',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Copy lỗi',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                navigator.clipboard.writeText(
                  `[${error?.response?.status}] ${JSON.stringify(error?.response?.data)} | ${error?.request?.responseURL}: ${JSON.stringify(error?.config?.data)}`,
                )
                Vue.swal({
                  icon: 'success',
                  title: 'Đã copy!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
          }
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    const isSelectAllTrip = (trips, searchArray) => {
      const checkTrip = trips.length === searchArray.length
      if (!checkTrip) {
        toastError({ title: 'messagesList.error', content: 'Vui lòng chọn tất cả hành trình!' })
        return false
      }
      const checkSegment = trips.every(t => Boolean(t))
      if (!checkSegment) {
        toastError({ title: 'messagesList.error', content: 'Vui lòng chọn tất cả hành trình!' })
        return false
      }
      return true
    }

    async function handleShowModalConfirm() { // check giá và ok
      const isValidated = isSelectAllTrip(selectedTrips.value, getSearchFlightArray.value)

      if ((['VJ'].includes(bookingData.value.source) && (bookingData.value.totalAmountToBePaid === 0))) {
        hasPaidTrip.value = true
        isPayNow.value = true
      } else {
        isPayNow.value = false
      }

      if (isValidated) {
        isConfirm.value = false
        selectedTripToAdd.value = cloneDeep(selectedTrips.value)
        store.dispatch('app-reservation/setSelectedTrip', selectedTrips.value)
        await delay(200)
        this.$bvModal.show('modal-modify-add-flight-price')
      }
    }

    // SECTION: Tính giá thêm chuyến bay VJ =====================================
    const priceAdd = ref()
    const errorAdd = ref()

    function showPriceHandle() {
      const bookingData = props.bookingData
      isConfirm.value = false
      payloadAddFlight.value = null

      const payload = {
        contact: getUserData().employeeData.id,
        airline: bookingData?.airlines?.split(' | ')[0] ?? bookingData.source,
        source: bookingData.source,
        agencyCode: bookingData.agency,
        paxCode: bookingData.paxContact.code,
        pnrNumber: bookingData.bookingCode,
        paxList: bookingData.paxLists.map(passenger => ({
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          title: getPassengerTitle(passenger) || '',
          paxType: passenger.paxType,
          birthday: passenger?.birthday || null,
          paxId: passenger.paxId,
          parentPaxId: passenger.parentPaxId || '',
          PaxDocuments: passenger.document,
          eTicketNumber: '',
          pricingInfo: passenger.pricingInfo,
        })),
        itineraries: getItineraries(compact(tempSelectedTrips.value)),
      }

      priceAdd.value = false
      errorAdd.value = null

      addFlightsPrice(payload)
        .then(res => {
          priceAdd.value = { ...res.chargeAmount }
          payloadAddFlight.value = payload
        })
        .catch(error => {
          errorAdd.value = error?.message || error
        })
    }

    function showModalConfirmAddFlight() {
      if (!isEmpty(selectedTripToAdd.value) && ['VJ', 'VZ'].includes(selectedTripToAdd.value[0].airline)) {
        showPriceHandle(selectedTripToAdd.value)
      }
    }

    function backHandle() {
      // resetForm()
      this.$router.go(-1)
    }

    function hideModalConfirmAddFlight() {
      isPayNow.value = false
      hasPaidTrip.value = false
    }

    return {
      selectedTripToAdd,
      handleShowModalConfirm,
      handleSubmitAddFlight,
      formatCurrency,
      convertISODateTime,
      totalPrice,
      tabIndexNotSelect,
      isEmpty,
      tabIndex,
      isConfirm,
      selectedFlightIndex,
      backHandle,
      priceAdd,
      errorAdd,
      isPayNow,
      hasPaidTrip,
      showModalConfirmAddFlight,
      hideModalConfirmAddFlight,
      totalRefundAmountVJ,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
#footer ::v-deep {
  position: sticky;
  bottom: 0;
  z-index: 10;

  .bg-transparent {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
  }
}

.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 4px !important;
  }
}
</style>
